import React, { useContext, useEffect, useState } from 'react';
import { Button, Platform, StyleSheet } from 'react-native';
import Slider from '@react-native-community/slider';
import { tap } from 'rxjs/operators';
import { FontAwesome } from '@expo/vector-icons';
import { Metadata, MusicStatus, PlayStatus, QueueStatus } from '../aura/ClientModels';
import { MetadataResolverContext } from '../services/metadata-resolver';
import { MusicPlayerContext } from '../services/music-player';
import { QueueContext } from '../services/queue';

import { MonoText } from './StyledText';
import { Text, View } from './Themed';
import TrackListItem from './TrackListItem';
import { msToDuration } from '../utils/time';

export default function NowPlaying({ path }: { path: string }) {
  const musicPlayer = useContext(MusicPlayerContext);
  const metadataResolver = useContext(MetadataResolverContext);
  const theQueue = useContext(QueueContext);

  const [queueState, setQueueState] = useState<QueueStatus>({
    position: -1,
    queued: []
  });

  useEffect(() => {
    const sub = theQueue.getQueueStatus().pipe(
      tap(setQueueState)
    ).subscribe();

    return () => sub.unsubscribe();
  }, []);

  const [musicState, setMusicState] = useState<MusicStatus>({
    playing: PlayStatus.Stopped,
    positionMs: 0,
    durationMs: 0.1,
    __reallyPlaying: false,
    buffering: false
  });

  const [currentTrackMetadata, setCurrentTrackMetadata] = useState<Metadata>();

  useEffect(() => {
    const sub = musicPlayer.status().pipe(
      tap((s) => { setMusicState(s); }),
    ).subscribe();

    return () => sub.unsubscribe();
  }, []);

  useEffect(() => {
    if (!musicState.loaded) {
      return;
    }
    metadataResolver.metadataFromQueueableTrack(musicState.loaded)
      .then(setCurrentTrackMetadata);
  }, [musicState.loaded]);

  const canPlay = musicState.loaded && (musicState.playing !== PlayStatus.Playing);
  const canPause = musicState.playing === PlayStatus.Playing;
  const canStop = musicState.playing !== PlayStatus.Stopped;
  const canPlayPrev = queueState.size && (queueState.position >= 1);
  const canPlayNext = queueState.size && (queueState.position < queueState.size - 1);

  function setPositionMs(toMs: number) {
    console.log(toMs, musicState.positionMs, musicState.durationMs);
    musicPlayer.updatePosition(toMs);
  }

  function setPositionPercent(percent: number) {
    musicPlayer.updatePosition((percent * musicState.durationMs) / 100);
  }

  function setVolume(volume: number) {
    musicPlayer.updateVolume(volume);
  }

  // TODO: Add stop functionality to queue
  function stop() {
    theQueue.setPosition(0)
      .then(() => {
        theQueue.pause();
      });
  }

  const position = msToDuration(musicState.positionMs ?? 0);
  const duration = isNaN(musicState.durationMs) ? '[Unknown]' : msToDuration(musicState.durationMs ?? 0);

  return (
    <View>
      <View style={styles.getStartedContainer}>
        {
          musicState.loaded
          ? <TrackListItem metadata={currentTrackMetadata}></TrackListItem>
          : <Text
              style={styles.getStartedText}
              lightColor="rgba(0,0,0,0.8)"
              darkColor="rgba(255,255,255,0.8)">
              Nothing is playing
            </Text>
        }
        <View style={styles.controls}>
          <Button title="Prev" onPress={() => theQueue.previous()} disabled={!canPlayPrev} />
          <Button title="Play" onPress={() => theQueue.play()} disabled={!canPlay} />
          <Button title="Pause" onPress={() => theQueue.pause()} disabled={!canPause} />
          <Button title="Stop" onPress={stop} disabled={!canStop} />
          <Button title="Next" onPress={() => theQueue.next()} disabled={!canPlayNext} />
        </View>
        {
          musicState.loaded && <View style={styles.controls}>
            <Text
              style={styles.getStartedText}
              lightColor="rgba(0,0,0,0.8)"
              darkColor="rgba(255,255,255,0.8)">
              { position } / { duration }
            </Text>
          </View>
        }
        <View style={styles.slider}>
          {Platform.OS !== 'web' &&
            <Slider minimumValue={0} maximumValue={musicState.durationMs} value={musicState.positionMs}
              disabled={!musicState.loaded || musicState.buffering || musicState.playing === PlayStatus.Stopped}
              onSlidingComplete={setPositionMs}
              >
            </Slider>
          }
          {/* TODO: Fix on desktop web */}
          {Platform.OS === 'web' &&
            <Slider minimumValue={0} maximumValue={100} value={(musicState.positionMs/musicState.durationMs) * 100}
              disabled={!musicState.loaded || musicState.buffering || musicState.playing === PlayStatus.Stopped}
              onSlidingComplete={setPositionPercent} step={1}
              >
            </Slider>
          }
        </View>
        <View style={styles.slider}>
          <View style={styles.controls}>
            <FontAwesome name="volume-down" size={24} color="rgb(47, 149, 220)" />
              <Slider minimumValue={0} maximumValue={1} value={musicState.volume ?? 0.5}
                onSlidingComplete={setVolume} style={{width: "80%"}}
                >
              </Slider>
            <FontAwesome name="volume-up" size={24} color="rgb(47, 149, 220)" />
          </View>
        </View>
        <View
          style={[styles.codeHighlightContainer, styles.homeScreenFilename]}
          darkColor="rgba(255,255,255,0.05)"
          lightColor="rgba(0,0,0,0.05)">
          <MonoText>{path}</MonoText>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  developmentModeText: {
    marginBottom: 20,
    fontSize: 14,
    lineHeight: 19,
    textAlign: 'center',
  },
  contentContainer: {
    paddingTop: 30,
  },
  welcomeContainer: {
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 20,
  },
  welcomeImage: {
    width: 100,
    height: 80,
    resizeMode: 'contain',
    marginTop: 3,
    marginLeft: -10,
  },
  getStartedContainer: {
    alignItems: 'center',
    marginHorizontal: 50,
  },
  homeScreenFilename: {
    marginVertical: 7,
  },
  codeHighlightText: {
    color: 'rgba(96,100,109, 0.8)',
  },
  codeHighlightContainer: {
    borderRadius: 3,
    paddingHorizontal: 4,
  },
  getStartedText: {
    fontSize: 17,
    lineHeight: 24,
    textAlign: 'center',
  },
  helpContainer: {
    marginTop: 15,
    marginHorizontal: 20,
    alignItems: 'center',
  },
  helpLink: {
    paddingVertical: 15,
  },
  helpLinkText: {
    textAlign: 'center',
  },
  controls: {
    flexDirection: 'row',
  },
  slider: {
    width: 300,
    height: 80,
  },
});
