import React, { useCallback, useContext, useEffect, useState } from 'react';
import { FlatList, Platform, StyleSheet, TouchableOpacity, useColorScheme } from 'react-native';

import { Observable } from 'rxjs';

import { Text, View } from './Themed';

import { Metadata, QueueableTrack } from '../aura/ClientModels';
import TrackListItem, { TrackActions, TrackActionsDisplay } from './TrackListItem';
import { MetadataResolverContext } from '../services/metadata-resolver';
import { AntDesign, Entypo, Fontisto } from '@expo/vector-icons';
import DraggableFlatList, { RenderItemParams } from 'react-native-draggable-flatlist';

export type IdentifyTrack = IdentifyTrackBySelf | IdentifyTrackByMetadata | IdentifyTrackByContext | IdentifyTrackByPosition | undefined;

export interface IdentifyTrackBySelf {
  type: 'self';
  track: QueueableTrack;
}

export interface IdentifyTrackByMetadata {
  type: 'metadata';
  track: Metadata;
}

/** TODO: Use for queue */
export interface IdentifyTrackByContext {
  type: 'context';
  context: any;
}

export interface IdentifyTrackByPosition {
  type: 'position';
  position: number;
}

export interface ShowTrack {
  title: boolean;
  /** TODO: Add option to show if album artist !== artist */
  artist: boolean;
  album: boolean;
}

interface BasicTrackListProps {
  // selected: Observable<IdentifyTrack>;
  wasSelected?: (t: IdentifyTrack) => void;
  playing: Observable<IdentifyTrack>;
  onAction: (t: QueueableTrack, a: TrackActions, c?: IdentifyTrackByContext | IdentifyTrackByPosition) => void;
  tracks: Observable<QueueableTrack[]>;
  isNarrow: boolean;
  actions: TrackActions[];
  draggable?: boolean;
  onDrag?: (dragged: IdentifyTrackByPosition, moveAfter: IdentifyTrackByPosition) => void;
  show?: ShowTrack;
  noTracksPlaceholder?: React.ComponentType<any> | null | undefined;
}

function formatForEffect<T>(d: T): T {
  if (!d) {
    return d;
  }
  return JSON.stringify(d) as any;
}

function getIndex(it: IdentifyTrack, tracks: QueueableTrack[]): number {
  let selectedIndex = -1;
  if (!it) {
    return selectedIndex;
  }

  if (it.type === 'context') {
    throw new Error('Context is unsupported');
  }

  if (it.type === 'metadata') {
    throw new Error('Metadata is unsupported');
  }

  if (it.type === 'position') {
    return it.position;
  }

  if (it.type === 'self') {
    return tracks.findIndex(t => {
      if (!t || !it.track) {
        return false;
      }
      if (it.track.CollectionID && (t.CollectionID !== it.track.CollectionID)) {
        return false;
      }
      if (it.track.ServerID && (t.ServerID !== it.track.ServerID)) {
        return false;
      }
      return it.track.TrackID === t.TrackID;
    });
  }

  return selectedIndex;
}

const MAX_HEIGHT = 80;

export default function BasicTrackList(props: BasicTrackListProps) {
  const metadataResolver = useContext(MetadataResolverContext);

  const [playing, setPlaying] = useState<IdentifyTrack>(undefined);
  useEffect(() => {
    if (!props.playing) {
      return;
    }

    const sub = props.playing.subscribe((v) => {
      setPlaying(v);
    });

    return () => { sub.unsubscribe(); };
  }, [props.playing]);

  const [tracks, setTracks] = useState<QueueableTrack[]>([]);
  useEffect(() => {
    if (!props.tracks) {
      return;
    }

    const sub = props.tracks.subscribe((v) => {
      setTracks(v);
    });

    return () => { sub.unsubscribe(); };
  }, [props.tracks]);

  const [trackMetadata, setTrackMetadata] = useState<{[trackId: string]: Metadata}>({});

  const queuedIds = (tracks || []).map(q => q.TrackID);

  useEffect(() => {
    const hasMetadata = Object.keys(trackMetadata);
    const toFetch = tracks.filter((inQueue) => {
      return !hasMetadata.includes(inQueue.TrackID);
    });

    let cancelled = false;

    const newTrackMetadata: {[trackId: string]: Metadata} = {};

    Promise.all(toFetch.map(
      q => {
        return metadataResolver.metadataFromQueueableTrack(q)
          .catch(() => Promise.resolve(undefined))
          .then((m) => {
            if (!m) {
              return;
            }
            newTrackMetadata[q.TrackID] = m;
          })
      }))
      .then(() => {
        if (cancelled) {
          return;
        }
        setTrackMetadata({
          ...trackMetadata,
          ...newTrackMetadata
        });
      });

    return () => { cancelled = true };
  }, [formatForEffect(queuedIds)]);

  const [selected, setSelected] = useState<IdentifyTrack>(undefined);

  useEffect(() => {
    const cancel = setTimeout(() => {
      setSelected(undefined);
    }, 3 * 1000);

    return () => clearTimeout(cancel);
  }, [selected]);

  function onSelect(track: IdentifyTrack) {
    if (props.wasSelected) {
      props.wasSelected(track);
    }
    setSelected(track);
  }

  function onDrag({from, to}: {from: number, to: number}) {
    if (!props.onDrag) {
      return;
    }
    props.onDrag(
      { type: 'position', position: from },
      { type: 'position', position: to },
    );
  }

  const theme = useColorScheme();
  const colors = {
    light: "rgba(0,0,0,0.8)",
    dark: "rgba(255,255,255,0.8)",
    default: "rgba(127,127,127,0.8)",
  };
  let color = colors[theme ?? 'default'];
  if (Platform.OS === 'web') {
    color = colors.light;
  }

  let selectedIndex = getIndex(selected, tracks);
  let playingIndex = getIndex(playing, tracks);

  const renderItem = useCallback(
    ({ item, index, drag, isActive }: RenderItemParams<QueueableTrack>) => {
      const isPlaying = index === playingIndex;
      const isSelected = index === selectedIndex;

      const supportedActions = [
        { action: TrackActions.Play, show: !isPlaying },
        { action: TrackActions.RemoveFromQueue, show: true },
        { action: TrackActions.QueueNext, show: true },
        { action: TrackActions.QueueLast, show: true },
      ];
      const actions = supportedActions.filter((s) => {
        const a = s.action;
        return props.actions.includes(a);
      });
      const numActions = actions.length;

      let location = TrackActionsDisplay.None;
      if (!props.isNarrow) {
        location = TrackActionsDisplay.Beside;
      } else if (isSelected) {
        location = TrackActionsDisplay.Over;
      }

      const metadata = trackMetadata[item.TrackID];
      const selectable = !!metadata;
      const track: IdentifyTrackByPosition = {
        type: 'position',
        position: index ?? -1
      };

      return (
      <View style={[styles.controls, modifiedHeight]}>
        {props.draggable && (
          <TouchableOpacity
            style={{
              alignItems: "center",
              justifyContent: "center",
            }}
            onLongPress={drag}
          >
            <View style={[styles.playContainer, styles.dragContainer]}>
              {isActive && <AntDesign name="caretup" size={24} color={color} />}
              <Fontisto name="nav-icon-a" size={24} color={color} />
              {isActive && <AntDesign name="caretdown" size={24} color={color} />}
            </View>
          </TouchableOpacity>
        )}
        <View style={styles.playContainer}>
          {isPlaying && <Entypo name="controller-play" size={24} color={'orange'} />}
        </View>
        <TrackListItem
          metadata={metadata}
          actions={{actions, show: location, onAction: (action) => props.onAction(item, action, track), maxActions: numActions }}
          onPress={() => onSelect(track)}
          hidden={hidden}
        />
        <View style={styles.playContainer}>
          {/* For symmetry */}
        </View>
      </View>
      );
    },
    [trackMetadata, playing, playingIndex, selectedIndex]
  );

  if (!tracks || tracks.length === 0) {
    if (props.noTracksPlaceholder) {
      return <props.noTracksPlaceholder/>;
    }
    return (
      <Text
        style={{...styles.noTracksText, ...styles.container}}
        lightColor="rgba(0,0,0,0.8)"
        darkColor="rgba(255,255,255,0.8)">
        No Tracks
      </Text>
    );
  }

  const hidden = {
    artist: props.show && !props.show.artist,
    album: props.show && !props.show.album,
  };

  const modifiedHeight = {
    minHeight: MAX_HEIGHT
  };

  if (hidden.album) {
    modifiedHeight.minHeight -= MAX_HEIGHT * 0.25;
  }
  if (hidden.artist) {
    modifiedHeight.minHeight -= MAX_HEIGHT * 0.25;
  }

  return (
    <View style={styles.container}>
      <DraggableFlatList
        data={tracks}
        extraData={{ selectedIndex, playingIndex, trackMetadata }}
        renderItem={renderItem}
        keyExtractor={(item, i) => item.TrackID + i}
        onDragEnd={onDrag}
        ItemSeparatorComponent={() =>
          <View style={styles.container}>
            <View style={styles.separator} lightColor="#eee" darkColor="rgba(255,255,255,0.1)" />
          </View>
        }
        // renderPlaceholder={() => <View><Text>Dragging</Text></View>}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  noTracksText: {
    fontSize: 17,
    lineHeight: 24,
    textAlign: 'center',
  },
  controls: {
    flexDirection: 'row',
    minHeight: MAX_HEIGHT,
  },
  playContainer: {
    minWidth: 30,
    minHeight: 30,
    alignItems: 'center',
    flexDirection: 'row'
  },
  dragContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    paddingRight: 20,
    paddingLeft: 20,
  },
  separator: {
    marginVertical: 20,
    height: 1,
    width: '80%',
  },
});
