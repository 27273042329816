import React from 'react';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, shareReplay } from 'rxjs/operators';
import { MusicStatus, QueueableTrack, QueueStatus } from '../aura/ClientModels';
import { BasicMusicPlayer, MusicPlayer } from './music-player';

export enum QueuePosition {
  Next = 'Next',
  Last = 'Last',
}

export interface Queue {
  play(): Promise<void>;
  pause(): Promise<void>;

  enqueue(track: QueueableTrack, position: QueuePosition | number): Promise<number>;
  setPosition(position: number): Promise<QueueableTrack>;
  next(): Promise<QueueableTrack>;
  previous(): Promise<QueueableTrack>;
  remove(position: number): Promise<QueueableTrack>;
  move(from: number, toAfter: number): Promise<QueueableTrack>;
  restore(from: QueueStatus, positionMs?: number): Promise<void>;

  getQueueStatus(): Observable<QueueStatus>;
  getMusicStatus(): Observable<MusicStatus>;
}

export class BasicQueue implements Queue {
  queued: QueueableTrack[] = [];
  position = -1;

  private currentStatus = new BehaviorSubject<QueueStatus>({
    queued: [],
    position: -1,
    size: 0
  });

  private currentStatus$ = this.currentStatus.pipe(
    distinctUntilChanged(),
    shareReplay(1)
  );

  constructor(
    private musicPlayer: MusicPlayer
  ) {
    musicPlayer.thirsty().subscribe(() => {
      this.next();
    });
  }

  play(): Promise<void> {
    return this.musicPlayer.play();
  }

  pause(): Promise<void> {
    return this.musicPlayer.pause();
  }

  enqueue(track: QueueableTrack, position: number | QueuePosition): Promise<number> {
    let newPosition: number;
    if (typeof position === 'number') {
      newPosition = this.queued.length;
    } else if (position === QueuePosition.Last) {
      newPosition = this.queued.length;
    } else {
      newPosition = this.position + 1;
    }

    if (newPosition < 0 || newPosition > this.queued.length) {
      newPosition = this.queued.length;
    }

    if (newPosition < position) {
      this.position += 1;
    }

    this.queued.splice(newPosition, 0, track);

    this.updateStatus();

    return Promise.resolve(newPosition);
  }

  async restore(from: QueueStatus, positionMs?: number): Promise<void> {
    await this.musicPlayer.stop();
    this.queued = from.queued as QueueableTrack[];
    this.position = from.position;

    if (this.position >= 0) {
      await this.setPosition(this.position);
      if (positionMs) {
        await this.musicPlayer.updatePosition(positionMs);
      }
      // TODO: Remove
      await this.pause();
    }

    this.updateStatus();
  }

  // TODO: Add option for setPosition not to autoplay
  async setPosition(position: number): Promise<QueueableTrack> {
    if (!this.queued.length) {
      return Promise.reject();
    }

    if (position < 0 || position > this.queued.length) {
      return Promise.reject();
    }

    const trackToQueue = this.queued[position];

    await this.musicPlayer.enqueue(trackToQueue);
    await this.musicPlayer.playNext();
    this.position = position;
    this.updateStatus();

    return trackToQueue;
  }

  next(): Promise<QueueableTrack> {
    return this.setPosition(this.position + 1);
  }

  previous(): Promise<QueueableTrack> {
    return this.setPosition(this.position - 1);
  }

  // TODO: Handle when playing song is removed from queue
  remove(position: number): Promise<QueueableTrack> {
    if (position < 0 || position >= this.queued.length) {
      return Promise.reject();
    }
    const removed = this.queued.splice(position, 1)[0];
    if (position < this.position) {
      this.position -= 1;
    }
    this.updateStatus();
    return Promise.resolve(removed);
  }

  move(from: number, toAfter: number): Promise<QueueableTrack> {
    if (from < 0 || from >= this.queued.length) {
      return Promise.reject();
    }
    if (toAfter < 0 || toAfter >= this.queued.length) {
      return Promise.reject();
    }

    const newPosition = toAfter;
    if (from === this.position) {
      this.position = newPosition;
    } else if ((from > this.position) && (newPosition <= this.position)) {
      this.position += 1;
    } else if ((from < this.position) && (newPosition >= this.position)) {
      this.position -= 1;
    }

    const removed = this.queued.splice(from, 1)[0];
    this.queued.splice(newPosition, 0, removed);

    this.updateStatus();
    return Promise.resolve(removed);
  }

  getQueueStatus(): Observable<QueueStatus> {
    return this.currentStatus$;
  }

  getMusicStatus(): Observable<MusicStatus> {
    return this.musicPlayer.status();
  }

  private updateStatus(update?: Partial<QueueStatus>) {
    const current = this.currentStatus.getValue();

    const toUpdate: Partial<QueueStatus> = {
      queued: this.queued,
      position: this.position,
      size: this.queued.length,
      ...update
    };

    this.currentStatus.next({
      ...current,
      ...toUpdate
    });
  }
}

const mp = new BasicMusicPlayer(() => Promise.reject('No sound provider!'));
export const QueueContext = React.createContext<Queue>(
  new BasicQueue(mp)
);
