import React, { useContext } from 'react';
import { Button, StyleSheet } from 'react-native';

import { AppStateContext } from '../services/app-state';
import CurrentServerInfo from './CurrentServerInfo';
import ScrobbleSettings from './ScrobbleSettings';
import { View } from './Themed';

export default function Settings() {
  const appStateManager = useContext(AppStateContext);

  function resetSavedQueueStatus() {
    appStateManager.setMusicStatus(undefined as any);
  }

  function resetSavedMusicStatus() {
    appStateManager.setQueueStatus(undefined as any);
  }

  return (
    <>
      <View style={styles.container}>
        <CurrentServerInfo/>
        <Button title="Reset Saved Queue Status" onPress={resetSavedQueueStatus} />
        <Button title="Reset Saved Music Status" onPress={resetSavedMusicStatus} />
      </View>
      <View style={styles.container}>
        <ScrobbleSettings/>
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  text: {
    fontSize: 17,
    lineHeight: 24,
    textAlign: 'center',
  },
  container: {
    flex: 1,
    marginBottom: 10,
  },
});
