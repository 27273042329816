import React, { useContext, useEffect, useState } from 'react';
import { Button, Linking, StyleSheet } from 'react-native';

import { DummyScrobbler, ScrobblerContext } from '../services/scrobbling';
import { Text, View } from './Themed';

const dummy = new DummyScrobbler();

export default function ScrobbleSettings() {
  const scrobbler = useContext(ScrobblerContext);

  const [isScrobblerConfigured, setScrobblerConfigured] = useState<boolean>(false);
  const [isScrobblerSupported, setScrobblerSupported] = useState<boolean>(false);
  const [isConfiguringScrobbler, setConfiguringScrobbler] = useState<boolean>(false);
  const [scrobbleConfigureLink, setScrobbleConfigureLink] = useState<string>('');

  useEffect(() => {
    if (!scrobbler) {
      return;
    }

    setScrobblerConfigured(false);
    setScrobblerSupported(false);

    scrobbler.isConfigured()
      .then((c) => {
        setScrobblerSupported(c.supported);
        setScrobblerConfigured(c.configured);
      })
      // .catch(() => Promise.resolve());
  }, [scrobbler]);

  function configureScrobbler() {
    setConfiguringScrobbler(true);

    scrobbler.getConfigureLink()
      .then((link) => {
        if (window && window.open) {
          window.open(link, '_blank');
        } else {
          setScrobbleConfigureLink(link);
        }
      })
      // .catch(() => Promise.resolve())
      .finally(() => {
        setConfiguringScrobbler(false);
      })
  }

  if (scrobbler === dummy) {
    return (
      <View style={styles.container}>
        <Text>
          Please connect to a server to begin scrobbling
        </Text>
      </View>
    )
  }

  if (!isScrobblerSupported) {
    return (
      <View style={styles.container}>
        <Text>
          Scrobbling is not supported
        </Text>
      </View>
    )
  }

  return (
    <View style={styles.container}>
      {isScrobblerConfigured &&
        <Text>Scrobbler is configured</Text>
      }
      {!isScrobblerConfigured &&
        <Text>Scrobbler is not configured</Text>
      }
      {!isScrobblerConfigured &&
        <Button
          onPress={configureScrobbler}
          title={isConfiguringScrobbler ? "Configuring..." : "Configure Scrobbler"}
          disabled={isConfiguringScrobbler}
          />
      }
      { !!scrobbleConfigureLink &&
        <Text style={{color: 'blue'}}
          onPress={() => Linking.openURL(scrobbleConfigureLink)}>
          Login with lastfm
        </Text>
      }
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: 'black',
    borderWidth: 1,
    width: '100%'
  },
  text: {
    fontSize: 17,
    lineHeight: 24,
    textAlign: 'center',
  },
  trackText: {
    padding: 10,
    fontSize: 18,
    height: 44,
  },
  track: {
    flexDirection: 'row',
  }
});
