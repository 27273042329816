import { AuraAlbum, AuraArtist, AuraIdentifiable, AuraTrack } from './Models';

interface WithAuraServer {
  serverId: string;
}
type FromAuraServer<T extends AuraIdentifiable> = T & WithAuraServer;

export type ClientAuraTrack = FromAuraServer<AuraTrack>;
export type ClientAuraAlbum = FromAuraServer<AuraAlbum>;
export type ClientAuraArtist = FromAuraServer<AuraArtist>;

export interface QueueableTrack {
  ServerID: string;
  CollectionID: string;
  TrackID: string;
}

export interface Metadata {
  track?: AuraTrack,
  album?: AuraAlbum,
  artist?: AuraArtist
}

export interface AuraClientConfig {
  uri: string;
}

export interface QueueStatus {
  // TODO: Add wrapper around queueable track to have a unique id for each item
  queued: readonly QueueableTrack[];
  position: number;
  size?: number;
}

export enum PlayStatus {
  Playing = "Playing",
  Paused = "Paused",
  Stopped = "Stopped",
}

export interface MusicStatus {
  playing: PlayStatus;
  __reallyPlaying: boolean;
  buffering: boolean;
  volume?: number;
  positionMs: number;
  durationMs: number;
  loaded?: QueueableTrack;
  loadedNext?: QueueableTrack;
}

export interface ScrobbleConfig {
  enabled: boolean;
  broadcastCurrentTrackEnabled: boolean;
  threshold: number;
}

export interface CurrentListenRequest {
  "title": string;
	"artist": string;
	"duration"?: number;
	"album"?: string;
	"album-artist"?: string;
	"track-mbid"?: string;
	"aura-collection-id"?: string;
	"aura-track-id"?: string;
}

export interface CompleteListenRequest extends CurrentListenRequest {
	"timestamp": number;
}
