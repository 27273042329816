import React, { useContext, useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import { tap } from 'rxjs/operators';
import { StackScreenProps } from '@react-navigation/stack';
import { CommonActions, NavigationProp, StackActions, useNavigation } from '@react-navigation/native';

import { Text, View } from '../components/Themed';
import { AppState, AppStateContext, DEFAULT_STATE } from '../services/app-state';
import { BrowserParamList } from '../types';
import BrowseTracksList, { BrowseTracksListProps } from '../components/BrowseTracksList';

export type BrowseTracksScreenParams = BrowseTracksListProps;

export function navigateToBrowseTracksScreen(navigation: NavigationProp<any, any>, routeParams: BrowseTracksScreenParams) {
  const action = StackActions.push('BrowseTracksListScreen', routeParams);
  navigation.dispatch(action);
}

export default function BrowseTracksScreen(props: StackScreenProps<BrowserParamList, 'BrowseTracksListScreen'>) {
  const params: BrowseTracksScreenParams = props.route.params as any;
  // TODO: Validate params
  params;
  const appStateManager = useContext(AppStateContext);
  const navigation = useNavigation();

  // TODO: Consolidate with BrowseArtistsScreen
  useEffect(() => {
    if (navigation.canGoBack() || !navigation.isFocused()) {
      return;
    }

    const from = params.source === 'album' ? 'BrowseAlbumsListScreen' : 'BrowseArtistsListScreen';
    navigation.dispatch(
      CommonActions.reset({
        index: 2,
        routes: [
          { name: 'BrowserScreen'},
          { name: from },
          { name: 'BrowseTracksListScreen', params: params || {} },
        ],
      })
    );
  }, []);

  const [appState, setAppState] = useState<AppState>(DEFAULT_STATE);

  const browsing = appState.browsing?.uri;

  useEffect(() => {
    const sub = appStateManager.getStateUpdates().pipe(
      tap(setAppState)
    ).subscribe();

    return () => sub.unsubscribe();
  }, []);

  let description: string;
  if (params.source === 'album') {
    description = params.album?.attributes.title ?? `Album ${params.albumId}`;
  } else {
    description = params.artist?.attributes?.name ?? `Artist ${params.artistId}`;
  }

  return (
    <View style={styles.container}>
      <View style={styles.controls}>
        {browsing && <Text style={styles.item}>Browsing {browsing}</Text>}
        {<Text style={styles.item}>{description}</Text>}
        {!browsing && <Text style={styles.item}>No server configured</Text>}
      </View>
      <BrowseTracksList {...params}></BrowseTracksList>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
  textInput: {
    color: 'rgba(96,100,109, 0.8)',
  },
  item: {
    padding: 10,
    fontSize: 18,
    height: 44,
  },
  controls: {
    flexDirection: 'row',
  }
});
