import React from 'react';
import { BehaviorSubject, Observable, concat, from } from 'rxjs';
import { distinctUntilChanged, ignoreElements, shareReplay, tap } from 'rxjs/operators';
import { MusicStatus, QueueStatus, ScrobbleConfig } from '../aura/ClientModels';
import { createStorageManager, StorageManager } from './storage-manager';

interface Server {
  uri: string,
  name: string
}

export interface AppState {
  browsing?: Server,
  available: Server[],
  queueStatus?: QueueStatus,
  musicStatus?: MusicStatus,
  scrobbleConfig?: ScrobbleConfig,
}

export interface AppStateManager {
  ready(): Promise<void>;

  getStateUpdates(): Observable<Readonly<AppState>>;
  getState(): Readonly<AppState>;

  addServer(s: Server): void;
  setBrowsing(s: Server): void;
  setQueueStatus(queue: QueueStatus): void;
  setMusicStatus(music: MusicStatus): void;
  setScrobbleConfig(scrobble: ScrobbleConfig): void;
}

export const DEFAULT_STATE: AppState = { available: [] };
const STORAGE_KEY = 'app-state';

class BasicAppStateManager implements AppStateManager {
  private state: AppState = DEFAULT_STATE;
  private _ready: Promise<void>;

  private currentStatus = new BehaviorSubject<AppState>(DEFAULT_STATE);
  private currentStatus$: Observable<AppState>;

  constructor(
    private storageManager: StorageManager
  ) {
    this._ready = this.init();
    this.currentStatus$ = concat(
      from(this._ready).pipe(ignoreElements()),
      this.currentStatus.pipe(
        distinctUntilChanged(),
        shareReplay(1)
      )
    );
  }

  ready(): Promise<void> {
    return this._ready;
  }

  getStateUpdates(): Observable<Readonly<AppState>> {
    return this.currentStatus$;
  }

  getState(): Readonly<AppState> {
    return {
      browsing: this.state.browsing,
      available: this.state.available,
      musicStatus: this.state.musicStatus,
      queueStatus: this.state.queueStatus,
      scrobbleConfig: this.state.scrobbleConfig,
    };
  }

  addServer(s: Server): void {
    if (this.state.available.some(a => a.uri === s.uri)) {
      return;
    }
    const available = [s, ...this.state.available];
    this.state.available = available;
    this.updateStatus();
  }

  setBrowsing(s: Server): void {
    this.addServer(s);
    this.state.browsing = s;
    this.updateStatus();
  }

  setQueueStatus(queue: QueueStatus): void {
    this.state.queueStatus = queue;
    this.updateStatus();
  }

  setMusicStatus(music: MusicStatus): void {
    this.state.musicStatus = music;
    this.updateStatus();
  }

  setScrobbleConfig(scrobble: ScrobbleConfig): void {
    this.state.scrobbleConfig = scrobble;
    this.updateStatus();
  }

  private async init() {
    let state: AppState;
    try {
      state = await this.storageManager.load<AppState>(STORAGE_KEY);
    } catch (ex) {
      state = DEFAULT_STATE;
    }
    this.state = state;
    this.updateStatus();
    this.currentStatus$.pipe(tap(() => this.save())).subscribe();
  }

  private async save() {
    this.storageManager.save(STORAGE_KEY, this.state);
  }

  private updateStatus() {
    this.currentStatus.next(this.getState());
  }
}

export function createAppStateManager(s: StorageManager): AppStateManager {
  return new BasicAppStateManager(s);
}

export const AppStateContext = React.createContext<AppStateManager>(
  createAppStateManager(createStorageManager())
);