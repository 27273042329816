import { Picker } from '@react-native-picker/picker';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Button, StyleSheet, TextInput } from 'react-native';
import { tap } from 'rxjs/operators';
import { AppState, AppStateContext, DEFAULT_STATE } from '../services/app-state';
import { AuraClientManagerContext } from '../services/aura-client';
import { useThemeColor, View } from './Themed';

const DEFAULT_SERVER_URI_ENTRY = 'https://slaps/aura/';

export default function CurrentServerInfo() {
  const appStateManager = useContext(AppStateContext);
  const auraClientManager = useContext(AuraClientManagerContext);

  const [appState, setAppState] = useState<AppState>(DEFAULT_STATE);

  useEffect(() => {
    const sub = appStateManager.getStateUpdates().pipe(
      tap(setAppState)
    ).subscribe();

    return () => sub.unsubscribe();
  }, []);

  const [enteringServer, setEnteringServer] = useState<boolean>(false);
  
  const browsing = appState.browsing?.uri;
  const noServers = !appState.available.length;
  
  const [serverUriEntry, setServerUriEntry] = useState<string>(DEFAULT_SERVER_URI_ENTRY);
  
  const [savingServer, setSavingServer] = useState<boolean>(false);

  const color = useThemeColor({
    light: "rgba(0,0,0,0.8)",
    dark: "rgba(255,255,255,0.8)",
  }, 'text');

  function saveServer(uri: string) {
    setEnteringServer(false);
    setSavingServer(true);
    const server = {uri, name: uri}; 
    return auraClientManager.getClient(uri).ping()
      .then(() => {
        appStateManager.addServer(server);
        appStateManager.setBrowsing(server);
        setSavingServer(false);
      })
      .catch(() => {
        setEnteringServer(true);
        setSavingServer(false);
      });
  }

  return (
    <View>
      {(!browsing || enteringServer) &&
        <Fragment>
          {noServers && !enteringServer && 
            <Button title="Add server" onPress={() => setEnteringServer(true)}/>
          }
          {enteringServer &&
            <Fragment>
              <TextInput
                style={{color}}
                onChangeText={setServerUriEntry}
                value={serverUriEntry}
              />
              <Button title="Connect" onPress={() => saveServer(serverUriEntry)}/>
              <Button title="Cancel" onPress={() => setEnteringServer(false)}/>
            </Fragment>
          }
          {savingServer && `Connecting to ${serverUriEntry}`}
        </Fragment>
      }
      {(browsing && !enteringServer) &&
        <View>
          <Picker
            selectedValue={appState.browsing?.uri}
            style={{ height: 50, width: 150, color }}
            onValueChange={(uri) => appStateManager.setBrowsing({uri, name: uri})}
          >
            {appState.available.map(s => <Picker.Item key={s.name + s.uri} label={s.name} value={s.uri} />)}
          </Picker>
          <Button title="Add server" onPress={() => setEnteringServer(true)}/>
        </View>
      }
    </View>
  );
}

const styles = StyleSheet.create({
  text: {
    fontSize: 17,
    lineHeight: 24,
    textAlign: 'center',
  }
});
