import React from 'react';
import { combineLatest, EMPTY, interval, merge, Observable } from 'rxjs';
import { distinctUntilChanged, exhaustMap, filter, sample, tap, throttleTime } from 'rxjs/operators';

import { AuraClientConfig, ClientAuraTrack, CompleteListenRequest, CurrentListenRequest, MusicStatus, PlayStatus, QueueableTrack, ScrobbleConfig } from '../aura/ClientModels';
import { MetadataResolver } from './metadata-resolver';

export interface Scrobbler {
  isConfigured(): Promise<ScrobblingServerCapability>;
  getConfigureLink(): Promise<string>;

  submitCurrentlyListening(track: ClientAuraTrack): Promise<void>;
  submitListened(track: ClientAuraTrack, time: number): Promise<void>;
}

interface ScrobblingServerCapability {
  configured: boolean;
  supported: boolean;
}

interface ScrobbleTracking {
  playedMs: number;
  trackDurationMs: number;
  // TODO: Refactor to take into account same id twice in a row
  track: QueueableTrack;
  scrobbled: boolean;
  lastCheckedTrackPositionMs: number;
  wasPlayingWhenlastChecked: boolean;
}

class BasicScrobbler implements Scrobbler {
  private config?: AuraClientConfig;
  private tracking?: ScrobbleTracking;

  constructor(
    private config$: Observable<AuraClientConfig>,
    musicStatus$: Observable<MusicStatus>,
    private scrobbleConfig$: Observable<ScrobbleConfig>,
    private metadataResolver: MetadataResolver,
  ) {
    this.config$.subscribe(c => {
      this.config = c;
    });

    const notPlaying$ = musicStatus$.pipe(
      filter((s: MusicStatus) => {
        return !s.__reallyPlaying;
      })
    );
    const playing$ = musicStatus$.pipe(
      filter((s: MusicStatus) => {
        return s.__reallyPlaying;
      })
    );

    const POLL_RATE_MS = 1000;

    merge(
      notPlaying$,
      playing$.pipe(throttleTime(POLL_RATE_MS))
    ).pipe(
      filter(m => !!m.loaded),
      tap(m => {
        let newTracking = false;
        if (this.tracking) {
          const trackChanged = m.loaded!.TrackID !== this.tracking.track.TrackID;
          if (trackChanged || m.playing === PlayStatus.Stopped) {
            newTracking = true;
            this.submitIfEligible(this.tracking);
          }
        } else {
          newTracking = true;
        }

        // TODO: Count playing a track to ~99% and scrubbing to the start as a new play (configurable)
        if (newTracking) {
          this.tracking = {
            playedMs: 0,
            trackDurationMs: m.durationMs,
            track: m.loaded!,
            scrobbled: false,
            lastCheckedTrackPositionMs: 0,
            wasPlayingWhenlastChecked:  m.__reallyPlaying,
          };
          return;
        }

        this.tracking!.trackDurationMs = m.durationMs;

        if (this.tracking?.scrobbled) {
          return;
        }

        if (m.__reallyPlaying && this.tracking!.wasPlayingWhenlastChecked) {
          const timeElapsed = m.positionMs - this.tracking!.lastCheckedTrackPositionMs;
          this.tracking!.playedMs += Math.max(Math.min(timeElapsed, 1000), 0);
          this.tracking!.scrobbled = this.submitIfEligible(this.tracking!);
        }

        this.tracking!.lastCheckedTrackPositionMs = m.positionMs;
        this.tracking!.wasPlayingWhenlastChecked = m.__reallyPlaying;
      })
    ).subscribe();

    combineLatest([
      this.config$,
      this.scrobbleConfig$,
    ]).pipe(
      distinctUntilChanged(),
      exhaustMap(([auraConfig, scrobbleConfig]) => {
        if (!auraConfig.uri || !scrobbleConfig || !scrobbleConfig.enabled) {
          return EMPTY;
        }

        return musicStatus$.pipe(
          filter((s: MusicStatus) => {
            return s.__reallyPlaying && !!s.loaded;
          }),
          sample(interval(20 * 1000)),
          tap(s => {
            metadataResolver.metadataFromQueueableTrack(s.loaded!)
            .then(m => {
              if (!m || !m.track) {
                return;
              }

              this.submitCurrentlyListening({
                attributes: {
                  title: m.track.attributes.title,
                  artist: m.track.attributes.artist,
                  album: m.track.attributes.album,
                  albumartist: m.track.attributes.albumartist
                }
              } as any)
            })
          })
        );
      })
    ).subscribe();
  }

  isConfigured(): Promise<ScrobblingServerCapability> {
    let p: Promise<Response> = Promise.reject();
    if (this.config) {
      p = fetch(this.config.uri + 'x/listen', {
        method: 'GET',
      })
    }
    return p
      .then(r => {
        if ((r.status >= 200) && (r.status < 300)) {
          return {
            configured: true,
            supported: true,
          };
        } else if (r.status === 401) {
          return {
            configured: false,
            supported: true,
          };
        }
        return Promise.reject();
      })
      .catch(() => {
        return {
          configured: false,
          supported: false,
        };
      });
  }

  getConfigureLink(): Promise<string> {
    let p: Promise<Response> = Promise.reject();
    if (this.config) {
      p = fetch(this.config.uri + 'x/listen', {
        method: 'POST',
      })
    }
    return p
      .then(r => {
        if (r.headers.has('Location')) {
          const h = r.headers.get('Location');
          if (h) {
            return h;
          }
        }
        return Promise.reject();
      });
  }

  async submitCurrentlyListening(track: ClientAuraTrack): Promise<void> {
    if (!track.attributes.artist && !track.attributes.title) {
      return Promise.reject('Track Name and Artist are required for scrobbling');
    }

    let p: Promise<Response> = Promise.reject();
    if (this.config) {
      const request = trackToCurrentListenRequest(track);
      p = fetch(this.config.uri + 'x/listen/current', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(request)
      })
    }

    return p
      .then(() => {});
  }

  submitListened(track: ClientAuraTrack): Promise<void> {
    throw new Error('Method not implemented.');
  }

  async submitListenedFromQueueable(track: QueueableTrack, now: number): Promise<void> {
    const m = await this.metadataResolver.metadataFromQueueableTrack(track);

    let p: Promise<Response> = Promise.reject();
    if (m.track && this.config) {
      const scrobble: CompleteListenRequest = {
        title: m.track.attributes.title,
        artist: m.track.attributes.artist,
        album: m.track.attributes.album,
        "album-artist": m.track.attributes.albumartist,
        timestamp: now,
        "aura-track-id": track.TrackID,
        "aura-collection-id": track.CollectionID,
      };

      const request = { scrobbles: [scrobble] };
      p = fetch(this.config.uri + 'x/listen/submit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(request)
      })
    }

    // TODO: If failed, put on a queue for later
    return p
      .then(() => {
        return fetch(this.config!.uri + 'x/listen/sync', {
          method: 'POST'
        });
      })
      .then(() => {})
      .catch(() => {});
  }

  private submitIfEligible(tracking: ScrobbleTracking): boolean {
    if (tracking.scrobbled) {
      return true;
    }

    // TODO: Make configurable
    const playedThreshold = 0.5;
    const thresholdMs = Math.min(
      Math.max(tracking.trackDurationMs * playedThreshold, 30 * 1000),
      4 * 60 * 1000
    );

    let eligibleToScrobble = tracking.playedMs > thresholdMs;
    // let eligibleToScrobble = tracking.playedMs > 5 * 1000;

    if (!eligibleToScrobble) {
      return false;
    }

    const now = Math.round(new Date().getTime() / 1000);

    this.submitListenedFromQueueable(tracking.track, now);

    return true;
  }
}

function trackToCurrentListenRequest(track: ClientAuraTrack): CurrentListenRequest {
  return {
    title: track.attributes.title,
    artist: track.attributes.artist,
    album: track.attributes.album,
    "album-artist": track.attributes.albumartist,
  }
}

export class DummyScrobbler implements Scrobbler {
  isConfigured(): Promise<ScrobblingServerCapability> {
    return Promise.reject('Method not implemented.');
  }
  getConfigureLink(): Promise<string> {
    return Promise.reject('Method not implemented.');
  }
  submitCurrentlyListening(track: ClientAuraTrack): Promise<void> {
    return Promise.reject('Method not implemented.');
  }
  submitListened(track: ClientAuraTrack): Promise<void> {
    return Promise.reject('Method not implemented.');
  }
}

export function createScrobbler(
  config$: Observable<AuraClientConfig>,
  musicStatus$: Observable<MusicStatus>,
  scrobbleConfig$: Observable<ScrobbleConfig>,
  metadataResolver: MetadataResolver,
): Scrobbler {
  return new BasicScrobbler(
    config$,
    musicStatus$,
    scrobbleConfig$,
    metadataResolver,
  );
}

export const ScrobblerContext = React.createContext<Scrobbler>(
  new DummyScrobbler()
);