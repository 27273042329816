export interface AuraWrapper<T> {
  data: T;
  included?: AuraIdentifiable[];
}

export enum AuraType {
  Artist = "artist",
  Album = "album",
  Track = "track",
}

export interface AuraServer {
  id: string;
  type: string;
  attributes: AuraServerAttributes;
}

export interface AuraServerAttributes {
  "aura-version": string;
  server: string;
  "server-version": string;
  "auth-required": boolean;
  features: AuraType[];
}

export interface AuraIdentifiable {
  id: string;
  type: AuraType;
}

export interface AuraEntityRelationships {
  albums?: AuraRelationshipData;
  artists?: AuraRelationshipData;
  tracks?: AuraRelationshipData;
}

export interface AuraRelationshipData {
  data: AuraEntityRelationshipDetail[];
}

export interface AuraEntityRelationshipDetail extends AuraIdentifiable {}

export interface AuraTrack extends AuraIdentifiable {
  attributes: AuraTrackAttributes;
}

export interface AuraTrackAttributes {
  title: string;
  artist: string;
  album: string;
  track: number;
  tracktotal: number;
  disc: number;
  disctotal: number;
  year: number;
  month: number;
  day: number;
  bpm: number;
  genre: string;
  "recording-mbid": string;
  "track-mbid": string;
  composer: string;
  albumartist: string;
  comments: string;
}

export interface AuraAlbum extends AuraIdentifiable {
  attributes: AuraAlbumAttributes;
  relationships: AuraEntityRelationships;
}

export interface AuraAlbumAttributes {
  title: string;
  artist: string;
  trackTotal: number;
  discTotal: number;
  year: number;
  month: number;
  day: number;
  genre: string;
  "release-mbid": string;
  "release-group-mbid": string;
}

export interface AuraArtist extends AuraIdentifiable {
  attributes: AuraArtistAttributes;
  relationships: AuraEntityRelationships;
}

export interface AuraArtistAttributes {
  name: string;
  "artist-mbid": string;
}
