import React, { useContext, useEffect, useState } from 'react';
import { StyleSheet, useWindowDimensions } from 'react-native';

import { EMPTY, Observable, of } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';

import { Text, View } from './Themed';

import { PlayStatus, QueueableTrack, QueueStatus } from '../aura/ClientModels';
import { QueueContext, QueuePosition } from '../services/queue';
import BasicTrackList, { IdentifyTrack, IdentifyTrackByContext, IdentifyTrackByPosition } from './BasicTrackList';
import { TrackActions } from './TrackListItem';

export default function Queue() {
  const theQueue = useContext(QueueContext);

  const [queueState, setQueueState] = useState<QueueStatus>({
    position: -1,
    queued: []
  });

  useEffect(() => {
    const sub = theQueue.getQueueStatus().pipe(
      tap(setQueueState)
    ).subscribe();

    return () => sub.unsubscribe();
  }, []);

  const tracks$: Observable<QueueableTrack[]> = of(queueState.queued) as Observable<QueueableTrack[]>;

  const playing$: Observable<IdentifyTrack> = theQueue.getMusicStatus().pipe(mergeMap(v => {
    if (v.playing === PlayStatus.Stopped) {
      return EMPTY;
    }
    if (!v.loaded) {
      return EMPTY;
    }
    return of({
      type: 'self',
      track: v.loaded
    }) as Observable<IdentifyTrack>;
  }));

  const handleAction = (t: QueueableTrack, action: TrackActions, c?: IdentifyTrackByContext | IdentifyTrackByPosition) => {
    switch (action) {
      case TrackActions.Play: {
        if (!c || c.type !== 'position') {
          break;
        }
        theQueue.setPosition(c.position);
        break;
      }
      case TrackActions.QueueNext: {
        theQueue.enqueue(t, QueuePosition.Next);
        break;
      }
      case TrackActions.QueueLast: {
        theQueue.enqueue(t, QueuePosition.Last);
        break;
      }
      case TrackActions.RemoveFromQueue: {
        if (!c || c.type !== 'position') {
          break;
        }
        theQueue.remove(c.position);
        break;
      }
    }
  }

  const windowWidth = useWindowDimensions().width;
  const isWide = windowWidth > 800;

  const show = {
    title: true,
    album: true,
    artist: true,
  };

  const actions = [TrackActions.Play, TrackActions.RemoveFromQueue];

  const nothingQueuedPlaceholder = () => <Text
    style={{...styles.getStartedText, ...styles.container}}
    lightColor="rgba(0,0,0,0.8)"
    darkColor="rgba(255,255,255,0.8)">
    Nothing Queued
  </Text>

  function onDrag(dragged: IdentifyTrackByPosition, moveAfter: IdentifyTrackByPosition) {
    theQueue.move(dragged.position, moveAfter.position);
  }

  return (
    <View style={styles.container}>
      <BasicTrackList
        playing={playing$}
        tracks={tracks$}
        show={show}
        actions={actions}
        isNarrow={!isWide}
        onAction={handleAction}
        noTracksPlaceholder={nothingQueuedPlaceholder}
        draggable={true}
        onDrag={onDrag}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  developmentModeText: {
    marginBottom: 20,
    fontSize: 14,
    lineHeight: 19,
    textAlign: 'center',
  },
  contentContainer: {
    paddingTop: 30,
  },
  welcomeContainer: {
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 20,
  },
  welcomeImage: {
    width: 100,
    height: 80,
    resizeMode: 'contain',
    marginTop: 3,
    marginLeft: -10,
  },
  getStartedContainer: {
    alignItems: 'center',
    marginHorizontal: 50,
  },
  homeScreenFilename: {
    marginVertical: 7,
  },
  codeHighlightText: {
    color: 'rgba(96,100,109, 0.8)',
  },
  codeHighlightContainer: {
    borderRadius: 3,
    paddingHorizontal: 4,
  },
  getStartedText: {
    fontSize: 17,
    lineHeight: 24,
    textAlign: 'center',
  },
  helpContainer: {
    marginTop: 15,
    marginHorizontal: 20,
    alignItems: 'center',
  },
  helpLink: {
    paddingVertical: 15,
  },
  helpLinkText: {
    textAlign: 'center',
  },
  controls: {
    flexDirection: 'row',
    minHeight: 80,
  },
  item: {
    padding: 10,
    fontSize: 18,
    height: 44,
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
});
