import { Entypo, Ionicons } from '@expo/vector-icons';
import { MaterialIcons } from '@expo/vector-icons';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createStackNavigator } from '@react-navigation/stack';
import * as React from 'react';

import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';
import NowPlayingScreen from '../screens/NowPlayingScreen';
import BrowserScreen from '../screens/BrowserScreen';
import QueueScreen from '../screens/QueueScreen';
import {
  BottomTabParamList,
  NowPlayingParamList,
  SettingsParamList,
  BrowserParamList,
  QueueParamList
} from '../types';
import SettingsScreen from '../screens/SettingsScreen';
import BrowserArtistsScreen from '../screens/BrowseArtistsScreen';
import BrowserAlbumsScreen from '../screens/BrowseAlbumsScreen';
import BrowseTracksScreen from '../screens/BrowseTracksScreen';

const BottomTab = createBottomTabNavigator<BottomTabParamList>();

export default function BottomTabNavigator() {
  const colorScheme = useColorScheme();

  return (
    <BottomTab.Navigator
      initialRouteName="Browser"
      tabBarOptions={{ activeTintColor: Colors[colorScheme].tint }}>
      <BottomTab.Screen
        name="Browser"
        component={BrowserNavigator}
        options={{
          title: "Browse",
          tabBarIcon: ({ color }) => <Ionicons name="albums-outline" size={24} color={color} />,
        }}
      />
      <BottomTab.Screen
        name="Queue"
        component={QueueNavigator}
        options={{
          title: "Queue",
          tabBarIcon: ({ color }) => <MaterialIcons name="queue-music" size={24} color={color} />,
        }}
      />
      <BottomTab.Screen
        name="NowPlaying"
        component={NowPlayingNavigator}
        options={{
          title: "Now Playing",
          tabBarIcon: ({ color }) => <Entypo name="controller-play" size={24} color={color} />,
        }}
      />
      <BottomTab.Screen
        name="Settings"
        component={SettingsNavigator}
        options={{
          title: "Settings",
          tabBarIcon: ({ color }) => <Entypo name="cog" size={24} color={color} />,
        }}
      />
    </BottomTab.Navigator>
  );
}

// You can explore the built-in icon families and icons on the web at:
// https://icons.expo.fyi/
function TabBarIcon(props: { name: React.ComponentProps<typeof Ionicons>['name']; color: string }) {
  return <Ionicons size={30} style={{ marginBottom: -3 }} {...props} />;
}

// Each tab has its own navigation stack, you can read more about this pattern here:
// https://reactnavigation.org/docs/tab-based-navigation#a-stack-navigator-for-each-tab
const BrowserStack = createStackNavigator<BrowserParamList>();

function BrowserNavigator() {
  return (
    <BrowserStack.Navigator initialRouteName="BrowserScreen">
      <BrowserStack.Screen
        name="BrowserScreen"
        component={BrowserScreen}
        options={{ headerTitle: 'Browser' }}
      />
      <BrowserStack.Screen
        name="BrowseArtistsListScreen"
        component={BrowserArtistsScreen}
        options={{ headerTitle: 'Artists' }}
      />
      <BrowserStack.Screen
        name="BrowseAlbumsListScreen"
        component={BrowserAlbumsScreen}
        options={{ headerTitle: 'Albums' }}
      />
      <BrowserStack.Screen
        name="BrowseTracksListScreen"
        component={BrowseTracksScreen}
        options={{ headerTitle: 'Tracks' }}
      />
    </BrowserStack.Navigator>
  );
}

const QueueStack = createStackNavigator<QueueParamList>();

function QueueNavigator() {
  return (
    <QueueStack.Navigator>
      <QueueStack.Screen
        name="QueueScreen"
        component={QueueScreen}
        options={{ headerTitle: 'Queue' }}
      />
    </QueueStack.Navigator>
  );
}

const NowPlayingStack = createStackNavigator<NowPlayingParamList>();

function NowPlayingNavigator() {
  return (
    <NowPlayingStack.Navigator>
      <NowPlayingStack.Screen
        name="NowPlayingScreen"
        component={NowPlayingScreen}
        options={{ headerTitle: 'Now Playing' }}
      />
    </NowPlayingStack.Navigator>
  );
}

const SettingsStack = createStackNavigator<SettingsParamList>();

function SettingsNavigator() {
  return (
    <SettingsStack.Navigator>
      <SettingsStack.Screen
        name="SettingsScreen"
        component={SettingsScreen}
        options={{ headerTitle: 'Settings' }}
      />
    </SettingsStack.Navigator>
  );
}
