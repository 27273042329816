const MS_IN_A_SEC = 1000;
const SEC_IN_A_MIN = 60;
const MIN_IN_A_HOUR = 60;

/** hh:mm:ss */
export function msToDuration(ms: number) {
  const totalSecs = ms / MS_IN_A_SEC;
  const mins = Math.floor(totalSecs / SEC_IN_A_MIN) + '';
  const secsLeftOver = Math.floor(totalSecs % SEC_IN_A_MIN) + '';

  return `${mins.padStart(2, '0')} : ${secsLeftOver.padStart(2, '0')}`;
}
