import { useNavigation } from '@react-navigation/core';
import React, { useContext, useEffect, useState } from 'react';
import { Button, FlatList, StyleSheet, TextInput } from 'react-native';
import { tap } from 'rxjs/operators';

import { ClientAuraAlbum, ClientAuraArtist } from '../aura/ClientModels';
import { AuraWrapper } from '../aura/Models';
import { navigateToBrowseTracksScreen } from '../screens/BrowseTracksScreen';
import { AppState, AppStateContext, DEFAULT_STATE } from '../services/app-state';
import { AuraClientManagerContext } from '../services/aura-client';

import { Text, useThemeColor, View } from './Themed';

interface BrowseAlbumsListProps {}

interface BrowseError {
  error: boolean;
  reason?: string;
}

export default function BrowseAlbumsList(props: BrowseAlbumsListProps) {
  // TODO: Validate params
  const appStateManager = useContext(AppStateContext);
  const auraClientManager = useContext(AuraClientManagerContext);
  const navigator = useNavigation();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<BrowseError>({ error: false });

  const [appState, setAppState] = useState<AppState>(DEFAULT_STATE);
  const [triedToFetch, setTriedToFetch] = useState(false);

  const browsing = appState.browsing?.uri;

  useEffect(() => {
    const setAppState$ = appStateManager.getStateUpdates().pipe(
      tap((v) => setAppState(v))
    );

    const setAppStateSub = setAppState$.subscribe();

    return () => setAppStateSub.unsubscribe();
  }, []);

  useEffect(() => {
    if (triedToFetch || !browsing) {
      return;
    }

    getAlbums();
  }, [triedToFetch, browsing]);

  const [searchText, setSearchText] = useState<string>('');
  const [albums, setAlbums] = useState<ClientAuraAlbum[]>([]);
  const [filteredAlbums, setFilteredAlbums] = useState<ClientAuraAlbum[]>([]);
  const noAlbums = albums.length === 0;
  const noFilteredAlbums = filteredAlbums.length === 0;

  useEffect(() => {
    const toSearch = searchText.trim().toLowerCase();
    if (!toSearch) {
      setFilteredAlbums(albums);
      return;
    }

    setFilteredAlbums(albums.filter(a => {
      return a.attributes.title.toLowerCase().includes(toSearch) ||
        (a.attributes.artist ?? '').toLowerCase().includes(toSearch);
    }));
  }, [searchText, albums]);

  function getAlbums() {
    setTriedToFetch(true);

    if (!browsing) {
      setError({ error: true, reason: 'No server set' });
      return;
    }

    setError({ error: false });
    setLoading(true);

    auraClientManager.getClient(browsing!).getAlbums()
      .then((response: AuraWrapper<ClientAuraAlbum[]>) => {
        if (response.data) {
          setAlbums(response.data);
        }
      })
      .catch(() => {
        setError({ error: true });
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const color = useThemeColor({
    light: "rgba(0,0,0,0.8)",
    dark: "rgba(255,255,255,0.8)",
  }, 'text');

  function renderSearch() {
    return (
      <TextInput
        style={{color, ...styles.text}}
        onChangeText={setSearchText}
        value={searchText}
        placeholder="Search albums"
        placeholderTextColor={color}
      />
    );
  }

  if (error.error) {
    return (
      <View style={styles.container}>
        <Text style={styles.text}>Failed to load Albums</Text>
        {error.reason && <Text style={styles.text}>{error.reason}</Text>}
        <Button title="Retry" onPress={getAlbums} />
      </View>
    );
  }
  if (loading) {
    return (
      <View style={styles.container}>
        <Text style={styles.text}>Loading Albums...</Text>
      </View>
    );
  }
  if (noAlbums) {
    return (
      <View style={styles.container}>
        <Text style={styles.text}>No Albums found</Text>
        <Button title="Retry" onPress={getAlbums} />
      </View>
    );
  }
  if (noFilteredAlbums) {
    return (
      <View style={styles.container}>
        {renderSearch()}
        <Text style={styles.text}>No matching Albums found</Text>
        <Button title="Reset Search" onPress={() => setSearchText('')} />
      </View>
    );
  }

  function navigateToAlbumsTracks(albumId: string) {
    navigateToBrowseTracksScreen(navigator, {
      source: 'album',
      albumId,
      album: albums.find(a => a.id === albumId)
    })
  }

  return (
    <View style={styles.container}>
      {renderSearch()}
      <FlatList
        style={{ width: '100%' }}
        data={filteredAlbums}
        renderItem={({ item }) => (
          <Text
            style={styles.artist}
            onPress={() => navigateToAlbumsTracks(item.id)}
            >
            {item.attributes.title}
          </Text>
        )}
        keyExtractor={(item) => item.id}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: 'black',
    borderWidth: 1,
    width: '100%'
  },
  text: {
    fontSize: 17,
    lineHeight: 24,
    textAlign: 'center',
  },
  artist: {
    padding: 10,
    fontSize: 18,
    height: 44,
  },
});
