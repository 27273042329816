import React from 'react';

import AsyncStorage from '@react-native-async-storage/async-storage';

export interface StorageManager {
  save(key: string, value: any): Promise<void>;
  load<T>(key: string): Promise<T>;
}

class BasicStorageManager implements StorageManager {
  save(key: string, value: any): Promise<void> {
    const serialised = JSON.stringify(value);
    return AsyncStorage.setItem(key, serialised);
  }

  load<T>(key: string): Promise<T> {
    return AsyncStorage.getItem(key).then((d) => {
      if (!d) {
        return Promise.reject();
      }
      return JSON.parse(d) as T;
    });
  }
}

export function createStorageManager(): StorageManager {
  return new BasicStorageManager();
}

export const StorageManagerContext = React.createContext<StorageManager>(
  createStorageManager()
);
