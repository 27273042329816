import React, { useContext, useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import { tap } from 'rxjs/operators';
import { StackScreenProps } from '@react-navigation/stack';
import { CommonActions, StackActions } from '@react-navigation/routers';
import { NavigationProp, useNavigation } from '@react-navigation/native';

import { Text, View } from '../components/Themed';
import { AppState, AppStateContext, DEFAULT_STATE } from '../services/app-state';
import { BrowserParamList } from '../types';
import BrowseArtistsList from '../components/BrowseArtistsList';

export interface BrowseArtistsScreenParams {}

export function navigateToBrowseArtistsScreen(navigation: NavigationProp<any, any>, routeParams: BrowseArtistsScreenParams) {
  const action = StackActions.push('BrowseArtistsListScreen', routeParams);
  navigation.dispatch(action);
}

export default function BrowseArtistsScreen(props: StackScreenProps<BrowserParamList, 'BrowseArtistsListScreen'>) {
  const params: BrowseArtistsScreenParams = props.route.params || {};
  params;
  const appStateManager = useContext(AppStateContext);
  const navigation = useNavigation();

  // TODO: Consolidate with BrowseTracksScreen
  useEffect(() => {
    if (navigation.canGoBack() || !navigation.isFocused()) {
      return;
    }

    navigation.dispatch(
      CommonActions.reset({
        index: 1,
        routes: [
          { name: 'BrowserScreen'},
          { name: 'BrowseArtistsListScreen'},
        ],
      })
    );
  }, []);

  const [appState, setAppState] = useState<AppState>(DEFAULT_STATE);

  const browsing = appState.browsing?.uri;

  useEffect(() => {
    const sub = appStateManager.getStateUpdates().pipe(
      tap(setAppState)
    ).subscribe();

    return () => sub.unsubscribe();
  }, []);

  return (
    <View style={styles.container}>
      <View style={styles.controls}>
        {browsing && <Text style={styles.item}>Browsing {browsing}</Text>}
        {!browsing && <Text style={styles.item}>No server configured</Text>}
      </View>
      <BrowseArtistsList></BrowseArtistsList>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
  textInput: {
    color: 'rgba(96,100,109, 0.8)',
  },
  item: {
    padding: 10,
    fontSize: 18,
    height: 44,
  },
  controls: {
    flexDirection: 'row',
  }
});
