import React from 'react';
import { ClientAuraArtist, ClientAuraAlbum, ClientAuraTrack, AuraClientConfig } from '../aura/ClientModels';
import { AuraEntityRelationshipDetail, AuraEntityRelationships, AuraIdentifiable, AuraServer, AuraType, AuraWrapper } from '../aura/Models';

interface AuraClientCapabilities {
  tracks: boolean;
  albums: boolean;
  artists: boolean;
}

export type AuraClientProvider = (config: AuraClientConfig) => AuraClient;

export interface AuraClient {
  getConfig(): AuraClientConfig;
  getCapabilities(): Promise<AuraClientCapabilities>;

  ping(): Promise<AuraServer>;

  /**
   *
   * @param allArtists if false, only album artists are returned
   */
  getArtists(allArtists?: boolean): Promise<AuraWrapper<ClientAuraArtist[]>>;
  getArtist(artistId: string): Promise<AuraWrapper<ClientAuraArtist>>;

  getAlbums(): Promise<AuraWrapper<ClientAuraAlbum[]>>;
  getAlbum(albumId: string): Promise<AuraWrapper<ClientAuraAlbum>>;

  getTracks(): Promise<AuraWrapper<ClientAuraTrack[]>>;
  getTrack(trackId: string): Promise<AuraWrapper<ClientAuraTrack>>;

  getAudioUri(trackId: string): Promise<string>;
}

class BasicAuraClient implements AuraClient {
  capabilities!: AuraClientCapabilities;

  constructor(
    private config: AuraClientConfig
  ) {}

  getConfig(): AuraClientConfig {
    return { ...this.config };
  }

  async getCapabilities(): Promise<AuraClientCapabilities> {
    if (this.capabilities) {
      return Promise.resolve({ ...this.capabilities });
    }

    const server = await this.ping();
    this.capabilities = {
      tracks: true,
      artists: server.attributes.features.includes(AuraType.Artist),
      albums: server.attributes.features.includes(AuraType.Album)
    };

    return { ...this.capabilities };
  }

  ping(): Promise<AuraServer> {
    return fetch(this.config.uri + 'server')
      .then(r => r.json());
  }
  async getArtists(allArtists?: boolean): Promise<AuraWrapper<ClientAuraArtist[]>> {
    const query = allArtists ? '' : 'include=albumArtistsOnly';
    const artists: AuraWrapper<ClientAuraArtist[]> = await fetch(this.config.uri + 'artists?' + query)
      .then(r => r.json());
    artists.data.forEach(artist => {
      artist.serverId = this.config.uri;
      this.fixUpRelationships(artist.relationships);
    });
    this.fixUpIncluded(artists.included);
    return artists;
  }
  async getArtist(artistId: string): Promise<AuraWrapper<ClientAuraArtist>> {
    const artist: AuraWrapper<ClientAuraArtist> = await fetch(this.config.uri + 'artists/' + artistId)
      .then(r => r.json());
    artist.data.serverId = this.config.uri;
    this.fixUpRelationships(artist.data.relationships);
    this.fixUpIncluded(artist.included);
    return artist;
  }
  async getAlbums(): Promise<AuraWrapper<ClientAuraAlbum[]>> {
    const albums: AuraWrapper<ClientAuraAlbum[]> = await fetch(this.config.uri + 'albums')
      .then(r => r.json());
    albums.data.forEach(album => {
      album.serverId = this.config.uri;
      this.fixUpRelationships(album.relationships);
    });
    this.fixUpIncluded(albums.included);
    return albums;
  }
  async getAlbum(albumId: string): Promise<AuraWrapper<ClientAuraAlbum>> {
    const album: AuraWrapper<ClientAuraAlbum> = await fetch(this.config.uri + 'albums/' + albumId)
      .then(r => r.json());
    album.data.serverId = this.config.uri;
    this.fixUpRelationships(album.data.relationships);
    this.fixUpIncluded(album.included);
    return album;
  }
  async getTracks(): Promise<AuraWrapper<ClientAuraTrack[]>> {
    const tracks: AuraWrapper<ClientAuraTrack[]> = await fetch(this.config.uri + 'tracks')
    .then(r => r.json());
    tracks.data.forEach(track => {
      track.serverId = this.config.uri
    });
    this.fixUpIncluded(tracks.included);
    return tracks;
  }
  async getTrack(trackId: string): Promise<AuraWrapper<ClientAuraTrack>> {
    const track: AuraWrapper<ClientAuraTrack> = await fetch(this.config.uri + 'tracks/' + trackId)
      .then(r => r.json());
    track.data.serverId = this.config.uri;
    this.fixUpIncluded(track.included);
    return track;
  }
  getAudioUri(trackId: string): Promise<string> {
    return Promise.resolve(`${this.config.uri}tracks/${trackId}/audio`);
  }
  private fixUpRelationships(relationships: AuraEntityRelationships) {
    if (!relationships) {
      return;
    }
    let toProcess: AuraEntityRelationshipDetail[] = [];
    if (relationships.albums && relationships.albums.data) {
      toProcess = toProcess.concat(relationships.albums.data);
    }
    if (relationships.artists && relationships.artists.data) {
      toProcess = toProcess.concat(relationships.artists.data);
    }
    if (relationships.tracks && relationships.tracks.data) {
      toProcess = toProcess.concat(relationships.tracks.data);
    }
    this.fixUpIncluded(toProcess);
  }
  private fixUpIncluded(entities?: AuraIdentifiable[]) {
    if (!entities) {
      return;
    }
    entities.forEach(d => {
      (d as any).serverId = this.config.uri;
    });
  }
}

export class AuraClientManager {
  private clients: Map<string, AuraClient> = new Map();

  getClient(serverId: string): AuraClient {
    if (!serverId) {
      throw new Error('no server id!');
    }

    let client = this.clients.get(serverId);

    if (!client) {
      client = new BasicAuraClient({
        uri: serverId
      });

      this.clients.set(serverId, client);
    }

    return client;
  }
}

export const AuraClientManagerContext = React.createContext<AuraClientManager>(
  new AuraClientManager()
);
