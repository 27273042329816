import React, { useContext, useEffect, useState } from 'react';
import { Button, StyleSheet } from 'react-native';
import { tap } from 'rxjs/operators';
import { StackScreenProps } from '@react-navigation/stack';

import { Text, View } from '../components/Themed';
import { AppState, AppStateContext, DEFAULT_STATE } from '../services/app-state';
import { navigateToBrowseArtistsScreen } from './BrowseArtistsScreen';
import { navigateToBrowseAlbumsScreen } from './BrowseAlbumsScreen';

export default function BrowserScreen(props: StackScreenProps<any, any>) {
  const appStateManager = useContext(AppStateContext);

  const [appState, setAppState] = useState<AppState>(DEFAULT_STATE);

  const browsing = appState.browsing?.uri;

  useEffect(() => {
    const sub = appStateManager.getStateUpdates().pipe(
      tap(setAppState)
    ).subscribe();

    return () => sub.unsubscribe();
  }, []);

  function goToArtists() {
    navigateToBrowseArtistsScreen(props.navigation, {});
  }

  function goToAlbums() {
    navigateToBrowseAlbumsScreen(props.navigation, {});
  }

  return (
    <View style={styles.container}>
      <View style={styles.controls}>
        {browsing && <Text style={styles.item}>Browsing {browsing}</Text>}
        {!browsing && <Text style={styles.item}>No server configured</Text>}
      </View>
      <Button title="Go To Artists" onPress={goToArtists}/>
      <Button title="Go To Albums" onPress={goToAlbums}/>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
  textInput: {
    color: 'rgba(96,100,109, 0.8)',
  },
  item: {
    padding: 10,
    fontSize: 18,
    height: 44,
  },
  controls: {
    flexDirection: 'row',
  }
});
