import React from 'react';
import { StyleSheet, TouchableWithoutFeedback, TouchableHighlight, Platform } from 'react-native';

import { Feather, FontAwesome5, Foundation, Ionicons, MaterialIcons } from '@expo/vector-icons';

import { Metadata } from '../aura/ClientModels';
import { Text, View } from './Themed';

interface Hidden {
  artist?: boolean,
  album?: boolean,
}

export enum TrackActions {
  Play,
  QueueNext,
  QueueLast,
  RemoveFromQueue,
}

type OnActionHandler = (action: TrackActions) => void;

export enum TrackActionsDisplay {
  None,
  Over,
  Beside
}

interface ActionProps {
  show?: TrackActionsDisplay,
  actions: { action: TrackActions, show: boolean }[],
  onAction: OnActionHandler,
  maxActions?: number,
}

interface TrackListItemProps {
  metadata?: Metadata,
  hidden?: Hidden,
  onPress?: () => void,
  actions?: ActionProps,
}

const nilHandler = () => {};

const onPlay = (handler: OnActionHandler) => { handler(TrackActions.Play); };
const onQueueNext = (handler: OnActionHandler) => { handler(TrackActions.QueueNext); };
const onQueueLast = (handler: OnActionHandler) => { handler(TrackActions.QueueLast); };
const onRemoveFromQueue = (handler: OnActionHandler) => { handler(TrackActions.RemoveFromQueue); };

export default function TrackListItem({ metadata, hidden, onPress, actions }: TrackListItemProps) {
  const title = metadata?.track?.attributes.title ?? '[Title Unknown]';
  const artist = metadata?.track?.attributes.artist ?? '[Artist Unknown]';
  const album = metadata?.track?.attributes.album ?? '[Album Unknown]';

  const showArtist = !(hidden?.artist ?? false);
  const showAlbum = !(hidden?.album ?? false);

  const _onPress: (() => void) = onPress ?? nilHandler;

  const showActions = actions?.show ?? TrackActionsDisplay.None;

  let actionsDisplay = <></>;
  if (showActions !== TrackActionsDisplay.None) {
    const onAction = actions?.onAction ?? nilHandler;

    actionsDisplay = (
      <View style={styles.actions}>
        {actions?.actions.map((a, i) => {
          if (!a.show) {
            return <View key={"dummy" + i} style={styles.dummyButton}></View>;
          }
          switch (a.action) {
            case TrackActions.Play: {
              return (
                <TouchableHighlight key={"Play"} onPress={() => onPlay(onAction)}>
                  <View style={styles.button}>
                    <FontAwesome5 name="play" style={styles.text} color="black" />
                  </View>
                </TouchableHighlight>
              );
            }
            case TrackActions.QueueNext: {
              return (
                <TouchableHighlight key={"QueueNext"} onPress={() => onQueueNext(onAction)}>
                  <View style={styles.button}>
                    <Text
                      style={styles.text}
                      lightColor="rgba(0,0,0,0.8)"
                      darkColor="rgba(255,255,255,0.8)">
                      Next
                    </Text>
                  </View>
                </TouchableHighlight>
              );
            }
            case TrackActions.QueueLast: {
              return (
                <TouchableHighlight key={"QueueLast"} onPress={() => onQueueLast(onAction)}>
                  <View style={styles.button}>
                    <Text
                      style={styles.text}
                      lightColor="rgba(0,0,0,0.8)"
                      darkColor="rgba(255,255,255,0.8)">
                      Last
                    </Text>
                  </View>
                </TouchableHighlight>
              );
            }
            case TrackActions.RemoveFromQueue: {
              return (
                <TouchableHighlight key={"RemoveFromQueue"} onPress={() => onRemoveFromQueue(onAction)}>
                  <View style={styles.button}>
                    <Foundation name="x" style={styles.text} color="black" />
                  </View>
                </TouchableHighlight>
              );
            }
            default: {
              return <></>;
            }
          }
        })}
      </View>
    );
  }

  if (showActions === TrackActionsDisplay.Over) {
    return actionsDisplay;
  }

  let actionsBeside = showActions === TrackActionsDisplay.Beside ? actionsDisplay : <></>;

  return (
    <TouchableWithoutFeedback onPress={() => { console.log('twf');  _onPress();} }>
      <View style={Platform.OS !== 'web' ? styles.containerNonWeb : styles.container}>
        <View style={styles.trackInfo}>
          <Text
            style={styles.text}
            lightColor="rgba(0,0,0,0.8)"
            darkColor="rgba(255,255,255,0.8)">
            {title}
          </Text>
          { showAlbum &&
          <View style={styles.infoLine}>
            <View style={styles.icon}>
              <MaterialIcons name="album" size={24} color="black" />
            </View>
            <Text
              style={styles.text}
              lightColor="rgba(0,0,0,0.8)"
              darkColor="rgba(255,255,255,0.8)">
              {album}
            </Text>
          </View>
          }
          { showArtist &&
          <View style={styles.infoLine}>
            <View style={styles.icon}>
              <Ionicons name="person-circle" size={24} color="black" />
            </View>
            <Text
              style={styles.text}
              lightColor="rgba(0,0,0,0.8)"
              darkColor="rgba(255,255,255,0.8)">
              {artist}
            </Text>
          </View>
          }
        </View>
        {actionsBeside}
      </View>
    </TouchableWithoutFeedback>
  );
}

const styles = StyleSheet.create({
  text: {
    fontSize: 17,
    lineHeight: 24,
    textAlign: 'center',
  },
  container: {
    flexDirection: 'row',
    flex: 1,
  },
  containerNonWeb: {
    flexDirection: 'row',
  },
  actions: {
    flexDirection: 'row',
  },
  infoLine: {
    flexDirection: 'row',
  },
  icon: {
    paddingRight: 10,
  },
  // TODO; Consolidate styles
  button: {
    // flex: 1,
    alignItems: 'center',
    backgroundColor: '#DDDDDD',
    // padding: 10,
    marginLeft: 3,
    marginRight: 3,
    width: 100,
  },
  dummyButton: {
    // flex: 1,
    marginLeft: 3,
    marginRight: 3,
    width: 100,
  },
  trackInfo: {
    flexGrow: 1,
    textAlign: 'center',
    alignItems: 'center',
  }
});
