import * as Linking from 'expo-linking';

export default {
  prefixes: [Linking.makeUrl('/')],
  config: {
    screens: {
      Root: {
        screens: {
          Browser: {
            screens: {
              BrowserScreen: 'browse',
              BrowseArtistsListScreen: {
                path: 'browse/artists'
              },
              BrowseAlbumsListScreen: {
                path: 'browse/albums'
              },
              BrowseTracksListScreen: {
                path: 'browse/tracks',
                stringify: {
                  artist: () => undefined,
                  album: () => undefined,
                },
                parse: {
                  artistId: (artistId: string) => unescape(artistId),
                  albumId: (albumId: string) => unescape(albumId)
                }
              },
            },
          },
          Queue: {
            screens: {
              QueueScreen: 'queue',
            },
          },
          NowPlaying: {
            screens: {
              NowPlayingScreen: 'now-playing',
            },
          },
          Settings: {
            screens: {
              SettingsScreen: 'settings',
            },
          },
        },
      },
      NotFound: '*',
    },
  },
};
