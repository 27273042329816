import React from 'react';
import { Audio, AVPlaybackStatus, AVPlaybackStatusToSet } from 'expo-av';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, shareReplay } from 'rxjs/operators';
import { MusicStatus, PlayStatus, QueueableTrack } from '../aura/ClientModels';

export interface MyAVPlaybackStatus {
  isLoaded: boolean;
  androidImplementation?: string;
  uri: string;
  progressUpdateIntervalMillis: number;
  durationMillis?: number;
  positionMillis: number;
  playableDurationMillis?: number;
  seekMillisToleranceBefore?: number;
  seekMillisToleranceAfter?: number;
  shouldPlay: boolean;
  isPlaying: boolean;
  isBuffering: boolean;
  rate: number;
  shouldCorrectPitch: boolean;
  volume: number;
  isMuted: boolean;
  isLooping: boolean;
  didJustFinish: boolean;
}

export interface MusicPlayer {
  play(): Promise<void>;
  playNext(): Promise<void>;
  pause(): Promise<void>;
  stop(): Promise<void>;
  updatePosition(toMs: number): Promise<void>;
  updateVolume(volume: number): Promise<void>;
  enqueue(t: QueueableTrack): Promise<void>;
  status(): Observable<MusicStatus>;
  thirsty(): Observable<void>;
}

interface SoundProvided {
  sound: Audio.Sound,
  ready: Promise<void>,
}

export type SoundProvider = (t: QueueableTrack, s?: AVPlaybackStatusToSet) => Promise<SoundProvided>;

export interface MusicStatus2 {
  playing: PlayStatus;
  __reallyPlaying: boolean;
  buffering: boolean;
  volume?: number;
  positionMs: number;
  durationMs: number;
  loaded?: QueueableTrack;
  loadedNext?: QueueableTrack;
}

const TINY_FLOAT = 1/100000;
export class BasicMusicPlayer implements MusicPlayer {
  private readonly _current?: SoundProvided;

  private readonly _playing  = PlayStatus.Stopped;
  private readonly __reallyPlaying = false;
  private readonly _buffering = false;
  private readonly _volume = 0.5;
  private readonly _positionMs = TINY_FLOAT;
  private readonly _durationMs = 0;
  private readonly _currentTrack?: QueueableTrack;
  private readonly _nextTrack?: QueueableTrack;

  private updatingPosition?: Promise<void>;
  private updatingVolume?: Promise<void>;

  private currentStatus = new BehaviorSubject<MusicStatus>({
    playing: this._playing,
    __reallyPlaying: this.__reallyPlaying,
    buffering: this._buffering,
    positionMs: this._positionMs,
    durationMs: this._durationMs,
    volume: this._volume
  });

  private currentStatus$ = this.currentStatus.pipe(
    distinctUntilChanged(),
    debounceTime(16),
    shareReplay(1)
  );

  private amThirsty$ = new Subject<void>();

  constructor(private soundProvider: SoundProvider) {}

  private get current(): SoundProvided | undefined {
    return this._current;
  }

  private set current(v: SoundProvided | undefined) {
    if (this._current === v) {
      return;
    }
    this.cleanupSound(this._current?.sound);
    if (!v) {
      this.currentTrack = undefined as unknown as QueueableTrack;
    }
    (this as any)._current = v;
  }

  private get playing(): PlayStatus {
    return this._playing;
  }

  private set playing(v: PlayStatus) {
    if (this._playing === v) {
      return;
    }
    (this._playing as PlayStatus) = v;
    this.updateStatus();
  }

  private get reallyPlaying(): boolean {
    return this.__reallyPlaying;
  }

  private set reallyPlaying(v: boolean) {
    if (this.__reallyPlaying === v) {
      return;
    }
    (this.__reallyPlaying as boolean) = v;
    this.updateStatus();
  }

  private get buffering(): boolean {
    return this._buffering;
  }

  private set buffering(v: boolean) {
    if (this._buffering === v) {
      return;
    }
    (this._buffering as boolean) = v;
    this.updateStatus();
  }

  private get volume(): number {
    return this._volume;
  }

  private set volume(v: number) {
    if (typeof v !== 'number') {
      return;
    }
    v = Math.fround(v);
    if (Math.fround(this._volume) === v) {
      return;
    }
    if (this._current) {
      const s = this._current!;
      if (s.sound._loaded) {
        this.updatingVolume = s.sound.setVolumeAsync(v).then();
      } else {
        this.updatingVolume = s.ready.then(() => {
          return s.sound.setVolumeAsync(v);
        })
        .then();
      }
    }
    (this._volume as number) = v;
    this.updateStatus();
  }

  private get positionMs(): number {
    return this._positionMs;
  }

  private set positionMs(v: number) {
    if (typeof v !== 'number') {
      return;
    }
    v = Math.fround(v);
    if (Math.fround(this._positionMs) === v) {
      return;
    }
    if (this._current) {
      const s = this._current!;
      this.updatingPosition = s.ready.then(() => {
        return s.sound.setPositionAsync(v);
      })
      .then();
    }
    (this._positionMs as number) = v;
    this.updateStatus();
  }

  private get durationMs(): number {
    return this._durationMs;
  }

  private set durationMs(v: number) {
    if (this._durationMs === v) {
      return;
    }
    (this._durationMs as number) = v;
    this.updateStatus();
  }

  private get currentTrack(): QueueableTrack | undefined {
    return this._currentTrack;
  }

  private set currentTrack(v: QueueableTrack | undefined) {
    if (this._currentTrack === v) {
      return;
    }
    (this._currentTrack as QueueableTrack | undefined) = v;
    console.warn(this._currentTrack);
    this.updateStatus();
  }

  private get nextTrack(): QueueableTrack | undefined {
    return this._nextTrack;
  }

  private set nextTrack(v: QueueableTrack | undefined) {
    if (this._nextTrack === v) {
      return;
    }
    (this._nextTrack as QueueableTrack | undefined) = v;
    this.updateStatus();
  }

  async play(): Promise<void> {
    if (this.current) {
      await this.current.ready;
      await this.current.sound.playAsync();
      this.playing = PlayStatus.Playing;
      // this.updateVolume(this.volume);
      this.updateStatus();
    } else {
      await this.autoQueueNextItem();
      if (this.current) {
        await this.play();
      }
    }
  }

  async playNext(): Promise<void> {
    if (this.nextTrack) {
      this.current = undefined as unknown as SoundProvided;
    }
    await this.play();
  }

  async pause(): Promise<void> {
    if (this.current) {
      await this.current.sound.pauseAsync();
      this.playing = PlayStatus.Paused;
      this.updateStatus();
    }
  }

  async stop(): Promise<void> {
    if (this.current && this.playing !== PlayStatus.Stopped) {
      await this.current.sound.stopAsync();
      this.playing = PlayStatus.Stopped;
      this.updateStatus();
    }
  }

  status(): Observable<MusicStatus> {
    return this.currentStatus$;
  }

  private updateStatus() {
    this.currentStatus.next({
      playing: this._playing,
      __reallyPlaying: this.__reallyPlaying,
      buffering: this._buffering,
      volume: this._volume,
      positionMs: this._positionMs,
      durationMs: this._durationMs,
      loaded: this._currentTrack,
      loadedNext: this._nextTrack,
    });
  }

  async enqueue(t: QueueableTrack) {
    this.nextTrack = t;
    await Audio.setAudioModeAsync({
      staysActiveInBackground: true
    });

    if (!this.currentTrack) {
      await this.autoQueueNextItem();
    }
    this.updateStatus();
  }

  thirsty(): Observable<void> {
    return this.amThirsty$;
  }

  updatePosition(toMs: number): Promise<void> {
    if (!this.current) {
      return Promise.resolve();
    }
    this.positionMs = toMs;
    return this.updatingPosition!;
  }

  updateVolume(volume: number): Promise<void> {
    if (!this.current || typeof volume !== 'number') {
      return Promise.resolve();
    }
    this.volume = volume;
    return this.updatingVolume!;
  }

  private async cleanupSound(sound: Audio.Sound | undefined): Promise<void> {
    if (!sound) {
      return Promise.resolve();
    }

    if (sound._loaded) {
      await sound.stopAsync();
      await sound.unloadAsync();
    }
  }

  private async afterPlayback() {
    await this.autoQueueNextItem();
    this.play();
  }

  private async autoQueueNextItem() {
    if (!this.nextTrack) {
      await this.stop();
      this.amThirsty$.next();
      return;
    }

    const next = await this.soundProvider(this.nextTrack, {volume: this.volume});
    // TODO: Unify setters for current & currentTrack
    this.current = next;
    this.currentTrack = this.nextTrack;
    this.nextTrack = undefined as unknown as QueueableTrack;
    const currentSound = (this.current?.sound as Audio.Sound);
    const l = (ss: AVPlaybackStatus) => {
      const s = ss as MyAVPlaybackStatus;
      this.durationMs = s.durationMillis as unknown as number;
      this.buffering = s.isBuffering;
      this.reallyPlaying = s.isPlaying;

      if (s.didJustFinish) {
        this.afterPlayback();
        currentSound.setOnPlaybackStatusUpdate(null);
      } else if (this._playing !== PlayStatus.Stopped) {
        this.playing = s.isPlaying ? PlayStatus.Playing : PlayStatus.Paused;
      }

      if (!s.didJustFinish) {
        (this._positionMs as unknown as number) = s.positionMillis;
        (this._volume as unknown as number) = s.volume;
      }

      if (s.isPlaying) {
        this.updateStatus();
      }
    };
    currentSound.setOnPlaybackStatusUpdate(l);
  }
}

export const MusicPlayerContext = React.createContext<MusicPlayer>(
  new BasicMusicPlayer(() => Promise.reject('No sound provider!'))
);
