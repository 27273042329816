import React from 'react';

import { Audio, AVPlaybackStatusToSet } from 'expo-av';

import { concat, EMPTY, from, merge, of } from 'rxjs';
import { filter, map, mergeMap, take, tap } from 'rxjs/operators';

import { QueueableTrack, Metadata } from '../aura/ClientModels';
import { AppStateContext, createAppStateManager } from '../services/app-state';
import { AuraClientManager, AuraClientManagerContext } from '../services/aura-client';
import { MetadataResolver, MetadataResolverContext } from '../services/metadata-resolver';
import { BasicMusicPlayer, MusicPlayerContext, SoundProvider } from '../services/music-player';
import { BasicQueue, QueueContext } from '../services/queue';
import { createStorageManager, StorageManagerContext } from '../services/storage-manager';
import { createScrobbler, ScrobblerContext } from '../services/scrobbling';

const auraClientManager = new AuraClientManager();
const musicPlayer = new BasicMusicPlayer(createSoundProvider(auraClientManager));
const queue = new BasicQueue(musicPlayer);
const metadataResolver = createMetadataResolver(auraClientManager);
const storageManager = createStorageManager();
const appStateManager = createAppStateManager(storageManager);
const scrobbler = createScrobbler(
  appStateManager.getStateUpdates().pipe(
    filter(s => {
      return !!s.browsing;
    }),
    map(s => {
      return { uri: s.browsing!.uri };
    })
  ),
  musicPlayer.status(),
  concat(
    of({
      enabled: true,
      broadcastCurrentTrackEnabled: true,
      threshold: 0
    }),
    appStateManager.getStateUpdates().pipe(
      filter(s => !!s.scrobbleConfig),
      map(s => s.scrobbleConfig!)
    )
  ),
  metadataResolver,
);

const restoreMusicAndQueue = from(appStateManager.ready())
.pipe(
  mergeMap(() => appStateManager.getStateUpdates()),
  take(1),
  mergeMap((as) => {
    const queueStatus = as.queueStatus;

    if (!queueStatus) {
      return EMPTY;
    }

    const musicStatus = as.musicStatus;

    let timeMs = (musicStatus && musicStatus.positionMs) ?? 0;

    return from(queue.restore(queueStatus, timeMs));
  })
);

const saveMusic = musicPlayer.status()
  .pipe(tap(s => appStateManager.setMusicStatus(s)));

const saveQueue = queue.getQueueStatus()
  .pipe(tap(s => appStateManager.setQueueStatus(s)));

concat(
  restoreMusicAndQueue,
  merge(saveMusic, saveQueue)
)
.subscribe();

function createSoundProvider(acm: AuraClientManager): SoundProvider {
  return async (t: QueueableTrack, s?: AVPlaybackStatusToSet) => {
    const client = acm.getClient(t.ServerID);
    if (!client) {
      throw new Error('No client found');
    }

    const uri = await client.getAudioUri(t.TrackID);
    const sound = new Audio.Sound();
    const ready = sound.loadAsync({uri}) as any as Promise<void>;
    if (s) {
      ready.then(() => {
        sound.setStatusAsync(s);
      });
    }
    return { ready, sound };
  };
}

function createMetadataResolver(acm: AuraClientManager): MetadataResolver {
  const cachedMetadata = new Map<QueueableTrack, Metadata>();

  return {
    metadataFromQueueableTrack: async (t) => {
      const client = acm.getClient(t.ServerID);
      if (!client) {
        throw new Error('No client found');
      }

      let metadata = cachedMetadata.get(JSON.stringify(t) as any);

      if (!metadata) {
        const track = await client.getTrack(t.TrackID);

        metadata = {
          track: track.data
          // TODO: Find linked albums/artists
        };

        cachedMetadata.set(JSON.stringify(t) as any, metadata);

        return metadata;
      }

      return metadata;
    },
    addMetadataForQueueableTrack: async (t, m) => {
      cachedMetadata.set(JSON.stringify(t) as any, m);
    }
  };
}

type ContextPair<T> = [React.Context<T>, T];

/** https://github.com/facebook/react/issues/14620#issuecomment-491366098 */
function composeContextProviders(contexts: ContextPair<any>[], child: JSX.Element) {
  return contexts.reduce((acc, [context, value]) => {
    return React.createElement(context.Provider, {value}, acc);
  }, child);
}

export function wrapWithAuraContextProviders(child: JSX.Element) {
  return composeContextProviders([
    [QueueContext, queue],
    [MusicPlayerContext, musicPlayer],
    [AuraClientManagerContext, auraClientManager],
    [MetadataResolverContext, metadataResolver],
    [AppStateContext, appStateManager],
    [StorageManagerContext, storageManager],
    [ScrobblerContext, scrobbler],
  ], child);
}