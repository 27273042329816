import React from 'react';
import { Metadata, QueueableTrack } from '../aura/ClientModels';

export interface MetadataResolver {
  metadataFromQueueableTrack(track: QueueableTrack): Promise<Metadata>;
  addMetadataForQueueableTrack(track: QueueableTrack, metadata: Metadata): Promise<void>;
}

export const MetadataResolverContext = React.createContext<MetadataResolver>({
  metadataFromQueueableTrack: () => Promise.reject('No resolver!'),
  addMetadataForQueueableTrack: () => Promise.reject('No resolver!'),
});
