import React, { useContext, useEffect, useState } from 'react';
import { Button, StyleSheet, useWindowDimensions } from 'react-native';
import { EMPTY, Observable, of } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';

import { ClientAuraAlbum, ClientAuraArtist, ClientAuraTrack, Metadata, PlayStatus, QueueableTrack } from '../aura/ClientModels';
import { AuraWrapper } from '../aura/Models';
import { AppState, AppStateContext, DEFAULT_STATE } from '../services/app-state';
import { AuraClientManagerContext } from '../services/aura-client';
import { MetadataResolverContext } from '../services/metadata-resolver';
import { QueueContext, QueuePosition } from '../services/queue';
import BasicTrackList, { IdentifyTrack, IdentifyTrackByContext, IdentifyTrackByPosition } from './BasicTrackList';

import { Text, View } from './Themed';
import { TrackActions } from './TrackListItem';

interface BrowseTracksListFromArtistProps {
  source: 'artist',
  artistId: string,
  artist?: ClientAuraArtist,
}

interface BrowseTracksListFromAlbumProps {
  source: 'album',
  albumId: string,
  album?: ClientAuraAlbum,
}

export type BrowseTracksListProps = BrowseTracksListFromArtistProps | BrowseTracksListFromAlbumProps;

interface BrowseError {
  error: boolean;
  reason?: string;
}

export default function BrowseTracksList(props: BrowseTracksListProps) {
  const appStateManager = useContext(AppStateContext);
  const auraClientManager = useContext(AuraClientManagerContext);
  const theQueue = useContext(QueueContext);
  const metadataResolver = useContext(MetadataResolverContext);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<BrowseError>({ error: false });

  const [appState, setAppState] = useState<AppState>(DEFAULT_STATE);
  const [triedToFetch, setTriedToFetch] = useState(false);

  const browsing = appState.browsing?.uri;

  useEffect(() => {
    const setAppState$ = appStateManager.getStateUpdates().pipe(
      tap((v) => setAppState(v))
    );

    const setAppStateSub = setAppState$.subscribe();

    return () => setAppStateSub.unsubscribe();
  }, []);

  function reload() {
    if ((props.source === 'artist') && props.artistId) {
      getTracksForArtist();
    } else if ((props.source === 'album') && props.albumId) {
      getTracksForAlbum();
    }
  }

  useEffect(() => {
    if (triedToFetch || !browsing) {
      return;
    }

    reload();
  }, [triedToFetch, browsing]);

  let artistFromProps: ClientAuraArtist | undefined;
  let albumFromProps: ClientAuraAlbum | undefined;
  if (props.source === 'album') {
    albumFromProps = props.album;
  } else if (props.source === 'artist') {
    artistFromProps = props.artist;
  }
  const [artist, setArtist] = useState<ClientAuraArtist | undefined>(artistFromProps);
  const [album, setAlbum] = useState<ClientAuraAlbum | undefined>(albumFromProps);
  const [tracks, setTracks] = useState<ClientAuraTrack[]>([]);
  const noTracks = tracks.length === 0;

  function getTracksForArtist() {
    setTriedToFetch(true);

    if (!browsing) {
      setError({ error: true, reason: 'No server set' });
      return;
    }

    if (!((props.source === 'artist') && props.artistId)) {
      setError({ error: true, reason: 'No artist' });
      return;
    }

    setError({ error: false });
    setLoading(true);

    auraClientManager.getClient(browsing!).getArtist(props.artistId)
      .then((response: AuraWrapper<ClientAuraArtist>) => {
        if (response.data) {
          const tracks: ClientAuraTrack[] = (response.included || []).filter(a => a.type === 'track') as any;
          tracks.forEach(t => {
            const metadata: Metadata = {
              track: t,
              artist: artist
            };
            metadataResolver.addMetadataForQueueableTrack(toQueueable(t), metadata);
          });
          setArtist(response.data);
          setTracks(
            tracks
            .sort((a, b) => {
              return a.attributes.track - b.attributes.track;
            })
            .sort((a, b) => {
              return b.attributes.album.localeCompare(a.attributes.album);
            })
            .sort((a, b) => {
              return a.attributes.year - b.attributes.year;
            })
          );
        } else {
          setTracks([]);
        }
      })
      .catch(() => {
        setError({ error: true });
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function getTracksForAlbum() {
    setTriedToFetch(true);

    if (!browsing) {
      setError({ error: true, reason: 'No server set' });
      return;
    }

    if (!((props.source === 'album') && props.albumId)) {
      setError({ error: true, reason: 'No album' });
      return;
    }

    setError({ error: false });
    setLoading(true);

    auraClientManager.getClient(browsing!).getAlbum(props.albumId)
      .then((response: AuraWrapper<ClientAuraAlbum>) => {
        if (response.data) {
          const tracks: ClientAuraTrack[] = (response.included || []).filter(a => a.type === 'track') as any;
          tracks.forEach(t => {
            const metadata: Metadata = {
              track: t,
              album: album
            };
            metadataResolver.addMetadataForQueueableTrack(toQueueable(t), metadata);
          });
          setAlbum(response.data);
          setTracks(tracks.sort((a, b) => { return a.attributes.track - b.attributes.track; }));
        } else {
          setTracks([]);
        }
      })
      .catch(() => {
        setError({ error: true });
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function toQueueable(track: ClientAuraTrack): QueueableTrack {
    return {
      TrackID: track.id,
      ServerID: track.serverId
    } as any;
  }

  async function playWithQueue(track: QueueableTrack) {
    const position = await theQueue.enqueue(track, QueuePosition.Next);
    await theQueue.setPosition(position);
    return theQueue.play();
  }

  const windowWidth = useWindowDimensions().width;
  const isNarrow = windowWidth <= 800;

  // TODO: Pull up somewhere
  const handleAction = (t: QueueableTrack, action: TrackActions, c?: IdentifyTrackByContext | IdentifyTrackByPosition) => {
    switch (action) {
      case TrackActions.Play: {
        if (!c || c.type !== 'position') {
          break;
        }
        playWithQueue(t);
        break;
      }
      case TrackActions.QueueNext: {
        theQueue.enqueue(t, QueuePosition.Next);
        break;
      }
      case TrackActions.QueueLast: {
        theQueue.enqueue(t, QueuePosition.Last);
        break;
      }
    }
  }

  if (error.error) {
    return (
      <View style={styles.container}>
        <Text style={styles.text}>Failed to load Tracks</Text>
        {error.reason && <Text style={styles.text}>{error.reason}</Text>}
        <Button title="Retry" onPress={reload} />
      </View>
    );
  }
  if (loading) {
    return (
      <View style={styles.container}>
        <Text style={styles.text}>Loading Tracks...</Text>
      </View>
    );
  }
  if (noTracks) {
    return (
      <View style={styles.container}>
        <Text style={styles.text}>No Tracks found</Text>
        <Button title="Retry" onPress={reload} />
      </View>
    );
  }

  const actions = [TrackActions.Play, TrackActions.QueueNext, TrackActions.QueueLast];
  const playing: Observable<IdentifyTrack> = theQueue.getMusicStatus().pipe(mergeMap(v => {
    if (v.playing === PlayStatus.Stopped) {
      return EMPTY;
    }
    if (!v.loaded) {
      return EMPTY;
    }
    return of({
      type: 'self',
      track: v.loaded
    }) as Observable<IdentifyTrack>;
  }));
  const queuableTracks: Observable<QueueableTrack[]> = of((tracks || []).map(toQueueable));

  const show = {
    title: true,
    album: props.source !== 'album',
    artist: props.source !== 'artist',
  }

  return (
    <View style={styles.container}>
      <BasicTrackList
        actions={actions}
        isNarrow={isNarrow}
        onAction={handleAction}
        playing={playing}
        tracks={queuableTracks}
        show={show}
        />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: 'black',
    borderWidth: 1,
    width: '100%'
  },
  text: {
    fontSize: 17,
    lineHeight: 24,
    textAlign: 'center',
  },
  trackText: {
    padding: 10,
    fontSize: 18,
    height: 44,
  },
  track: {
    flexDirection: 'row',
  }
});
